/* =========================
   General Styles
========================= */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #EAE9EB; /* Background color behind the map */
  width: 100vw; /* Full width of the viewport */
  height: 100vh; /* Full height of the viewport */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto; /* Allow vertical scrolling */
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure app takes the full height */
  width: 100%; /* Ensure the app takes up full width */
  box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

/* =========================
   Container Alignment
========================= */
.home-container {
  width: 50%; /* 50% of the screen width */
  margin: auto; /* Center the container */
  text-align: center; /* Center the text inside the container */
  padding-top: 40px;
  line-height: 1.8;
  box-sizing: border-box; /* Include padding and border in width/height */
}

@media only screen and (max-width: 768px) {
  .home-container {
    width: 100%; /* Full width on mobile */
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* =========================
   Header and Menu Styles
========================= */
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
  height: 30px;
  padding: 10px;
  z-index: 1000;
  text-align: center; /* Ensures text in header remains center-aligned */
}

.menu {
  list-style: none;
  display: flex;
  gap: 40px;
  padding: 0;
  margin: 0;
}

.menu li {
  display: inline-block;
  color: white !important; /* Force white font for menu */
  font-size: 18px;
  cursor: pointer;
}

.menu li a {
  color: white !important; /* Ensure white color for <Link> elements */
  text-decoration: none;
}

.menu li:hover {
  text-decoration: underline;
}

/* =========================
   Hamburger Menu Styles
========================= */
.hamburger {
  display: none; /* Hidden on larger screens */
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
}

/* Hamburger Menu Visibility for Smaller Screens */
@media only screen and (max-width: 768px) {
  .menu {
    display: none; /* Hide the menu by default */
    flex-direction: column;
    position: fixed; /* Fixed positioning */
    top: 50px; /* Ensures menu appears below the header (assuming header height is around 50px) */
    left: 0;
    width: 100vw; /* Full width of the viewport */
    height: 40vh; /* Cover the top 20% of the screen */
    background-color: #333; /* Ensure background color is visible */
    justify-content: center; /* Center menu items vertically */
    align-items: center; /* Center menu items horizontally */
    z-index: 1000; /* Keep the menu on top */
  }

  .menu.open {
    display: flex; /* Show menu when opened */
  }

  .hamburger {
    display: flex; /* Show hamburger icon on small screens */
  }

  .menu li {
    padding: 5px 0; /* Smaller padding for each menu item */
    text-align: center;
    border-bottom: 1px solid #555;
    width: 100%; /* Ensure menu items take full width */
  }
}

/* =========================
   Title and Map Section
========================= */
.title {
  text-align: center;
  margin-top: 100px; /* Ensure spacing from fixed header */
  font-size: 50px;
  font-weight: bold;
  color: #4D4D4D;
}

.map-section {
  padding: 1px;
  margin: auto;
  width: 100%; /* Ensure map uses the full width */
  max-width: 100%; /* Prevent map from overflowing horizontally */
  margin-bottom: 5px; /* Ensure enough space below the map */
  height: 90vh; /* Ensure the map uses 90% of the screen height */
  max-height: 90vh; /* Ensure it never exceeds the full height of the viewport */
  box-sizing: border-box; /* Ensure padding and borders are included in width/height */
  z-index: 1; /* Ensure map does not overlap other elements */
}

@media only screen and (max-width: 768px) {
  .map-section {
    height: 60vh; /* Adjust the height of the map for mobile screens */
    max-height: 60vh; /* Ensure it never exceeds the full height of the viewport */
    width: 100%; /* Full width for mobile */
    margin-bottom: 5px; /* Ensure enough space below the map */
    max-width: 100%; /* Set a maximum width to ensure visibility */
    max-height: 100vh; /* Ensure it never exceeds the full height of the viewport */
  }
}

@media only screen and (max-width: 480px) {
  .map-section {
    height: 70vh; /* Set a fixed height */
    width: 100%; /* Full width */
    margin-bottom: 140px; /* Ensure enough space below the map */
    max-width: 100%; /* Set a maximum width to ensure visibility */
    max-height: 70vh; /* Ensure it never exceeds the full height of the viewport */
    margin: auto; /* Center the map horizontally */
  }
}

@media only screen and (max-width: 320px) {
  .map-section {
    height: 45vh; /* Further reduce height for very small screens */
    width: 100%;
    margin-bottom: 140px; /* Ensure enough space below the map */
    max-width: 100%; /* Ensure it fits within small screens */
    max-height: 45vh; /* Ensure it never exceeds the full height of the viewport */
    margin: auto;
  }
}

/* =========================
   Leaflet Popup Styles (Updated)
========================= */
.leaflet-popup-content {
  max-width: 250px; /* Slightly larger max width for better readability */
  font-size: 14px; /* Adjust font size inside the popup */
  word-wrap: break-word; /* Ensure long words break properly */
}

.leaflet-popup-content-wrapper {
  padding: 15px; /* Adjust padding inside the popup for more spacing */
  border-radius: 8px; /* Add rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow for a modern look */
}

.leaflet-popup-tip {
  display: none; /* Hide the arrow for a floating effect */
}

.leaflet-popup-close-button {
  font-size: 16px; /* Adjust the size of the close button */
  top: 4px;
  right: 4px;
}

/* =========================
   Custom Centered Popup Styles
========================= */
.centered-popup {
  position: absolute;
  top: 50%;  /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust to ensure perfect centering */
  background-color: white; /* Popup background */
  z-index: 1000; /* Ensure it's above other elements */
  width: 220px; /* Fix the width of the popup */
  height: 170px; /* Set the height of the popup */
  padding: 20px; /* Padding inside the popup */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  text-align: center; /* Center the content */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Ensure text truncation with ellipsis */
  white-space: nowrap; /* Prevent text wrapping */
  position: relative; /* Ensure the 'x' button can be positioned */
}

/* Close 'x' in the top-right corner */
.popup-close-x {
  position: absolute;
  top: 8px; /* Position from the top */
  right: 12px; /* Position from the right */
  font-size: 18px; /* Adjust font size */
  font-weight: bold; /* Make it bold */
  cursor: pointer; /* Show pointer cursor */
  color: #4D4D4D; /* Color matching the design */
}

/* Schließen button */
.popup-close-button {
  margin-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 8px 16px;
  background-color: #4D4D4D; /* Purple background */
  color: white; /* White text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Hover effect for Schließen button */
.popup-close-button:hover {
  background-color: #4D4D4D; /* Darker purple on hover */
}



/* =========================
   Table Section
========================= */
.table-section {
  text-align: left;
  margin-top: 10px; /* Ensure space between map and table */
  margin-bottom: 20px;
  padding-top: 50px; /* Add extra padding to push table down */
  z-index: 2; /* Ensure table is rendered above other elements */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

thead {
  background-color: #f2f2f2;
  text-align: left;
  color: #4D4D4D;
}

tbody tr {
  color: #4D4D4D;
  border-bottom: 1px solid #ddd;
}

tbody td {
  padding: 12px;
  color: #4D4D4D;
}

/* =========================
   State Tooltip Styles
========================= */
.state-tooltip {
  font-weight: bold;
  background-color: white;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* =========================
   Pulsating Marker Styles
========================= */
.pulsating-marker {
  background: #4D4D4D;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
}

.pulsating-marker::after {
  content: '';
  width: 24px;
  height: 24px;
  border: 2px solid #4D4D4D;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  left: -6px;
  animation: pulse 1s ease-out infinite;
  opacity: 0;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

/* =========================
   Button Styles
========================= */
.weiterlesen-button {
  background-color: #333;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.weiterlesen-button:hover {
  background-color: #555;
}

/* =========================
   Footer Styles
========================= */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 5px;
  margin-top: auto; /* Push footer to the bottom if content is short */
}

.footer p {
  margin: 0;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between Impressum and Datenschutz links */
}

.footer-links li {
  display: inline-block;
}

.footer-links li a {
  color: white !important; /* Force white font for Impressum and Datenschutz */
  text-decoration: none;
}

.footer-links li a:hover {
  text-decoration: underline;
}

/* =========================
   Ensure spacing for header and footer
========================= */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure root takes full height */
}

/* =========================
   Blog Post Styling
========================= */
.blog-container {
  width: 40%; /* Limit blog post to 40% of the screen width */
  margin: 0 auto; /* Center the container */
  padding-top: 40px;
  padding-bottom: 40px;
  line-height: 1.8;
  text-align: left;
  background-color: transparent; /* Keep the background transparent */
}

.top-image {
  width: 100%; /* Image should take up the full width of the container */
  border-radius: 8px; /* Optional: rounded corners for a modern look */
  margin-bottom: 30px; /* Space between the image and the heading */
}

/* =========================
   Heading and Paragraph Styles
========================= */
h1 {
  color: #4D4D4D;
  font-size: 48px;
  margin-bottom: 30px;
  font-weight: bold;
}

h2, h3, h4 {
  color: #4D4D4D;
  margin-top: 30px;
}

p, ul {
  color: #4D4D4D;
  margin-bottom: 20px;
}

ul {
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}

ul li a {
  color: #4D4D4D;
  text-decoration: none;
}

ul li a:hover {
  text-decoration: underline;
}

/* =========================
   About Us Page Styling
========================= */
.about-container {
  width: 40%; /* Limit content to 40% of the screen width */
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  line-height: 1.8;
  text-align: left;
  background-color: transparent;
}

.image-row {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.bottom-image {
  width: 48%;
  object-fit: cover;
  border-radius: 8px;
}

.bold-link {
  font-weight: bold;
  color: #5B24B2;
  text-decoration: none;
}

.bold-link:hover {
  text-decoration: underline;
}

/* =========================
   Impressum and Datenschutz Styling
========================= */
.datenschutz-container, .impressum-container {
  width: 40%; /* Keep the sections limited to 40% width */
  margin: auto; /* Center the content */
}

/* =========================
   Responsive Design for Smaller Screens
========================= */
@media only screen and (max-width: 768px) {
  .menu {
    display: none; /* Hide menu on small screens */
    flex-direction: column;
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: #333;
  }

  .menu.open {
    display: flex; /* Display the menu when opened */
    width: 100%;
    text-align: center;
  }

  .hamburger {
    display: flex; /* Show hamburger on small screens */
  }

  .menu li {
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px solid #555;
  }

  .blog-container, .about-container, .datenschutz-container, .impressum-container {
    width: 90%; /* Adjust content width for smaller screens */
  }

  .top-image {
    width: 100%; /* Ensure full-width image on smaller screens */
  }

  .title {
    font-size: 36px;
  }

  .header {
    padding: 5px;
    height: 50px;
  }

  .footer {
    padding: 10px;
    font-size: 14px;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px;
  }
}

/* =========================
   Very Small Screens (Max Width: 480px)
========================= */
@media only screen and (max-width: 480px) {
  h1 {
    font-size: 36px;
  }

  h2, h3, h4 {
    font-size: 20px;
  }

  p, ul {
    font-size: 16px;
  }

  .weiterlesen-button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .header, .footer {
    font-size: 14px;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px;
  }
}

.large-number {
  font-size: 30px; /* Adjust this size as needed */
  font-weight: bold; /* Optional: Make the number bold */
  color: #5B24B2; /* Optional: Color to match the style of your website */
}

/* Decrease font size for smaller screens */
@media only screen and (max-width: 768px) {
  .title {
    font-size: 36px; /* Adjust font size for screens smaller than 768px */
  }
}

@media only screen and (max-width: 480px) {
  .title {
    font-size: 30px; /* Further decrease font size for very small screens */
  }
}

/* Default styles for larger screens */
.intro-text {
  font-size: 18px; /* Adjust the size as needed */
}

.betrugsradar-text {
  font-size: 18px; /* Adjust the size as needed */
}

/* Adjust font sizes for smaller screens */
@media only screen and (max-width: 768px) {
  .intro-text {
    font-size: 14px; /* Decrease font size on mobile screens */
  }

  .betrugsradar-text {
    font-size: 14px; /* Decrease font size on mobile screens */
  }
}

@media only screen and (max-width: 480px) {
  .intro-text {
    font-size: 12px; /* Further decrease font size for very small screens */
  }

  .betrugsradar-text {
    font-size: 12px; /* Further decrease font size for very small screens */
  }
}
/* Styles for scrollbar on mobile */
@media only screen and (max-width: 768px) {
  /* Target the scrollbar for the entire body */
  body::-webkit-scrollbar {
    width: 16px; /* Double the default width of 8px */
  }

  /* Track of the scrollbar */
  body::-webkit-scrollbar-track {
    background: #f1f1f1; /* Lighter track background */
  }

  /* Handle of the scrollbar */
  body::-webkit-scrollbar-thumb {
    background-color: #5B24B2; /* Purple color for the scrollbar */
    border-radius: 10px; /* Rounded scrollbar handle */
    border: 3px solid #f1f1f1; /* Adds a bit of padding around the scrollbar */
  }

  /* Handle hover effect */
  body::-webkit-scrollbar-thumb:hover {
    background-color: #4a1d91; /* Darker purple on hover */
  }


/* =========================
   Enkeltrick / Blog Page Styling
========================= */
/* Blog Card Container */
.blog-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Space between the cards */
  padding: 20px;
  max-width: 50%; /* Ensures the cards container doesn’t exceed 50% of the screen width */
  margin: 0 auto; /* Centers the container */
}

/* Individual Blog Card */
.blog-card {
  flex: 1 1 45%; /* Ensures each card takes up to 45% of container width for two per row */
  max-width: 150px; /* Limits the card width */
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

/* Image Styling - resized to 20% */
.blog-card-image {
  width: 80px; /* Approximately 20% of a standard larger size */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px;
}

/* Title Styling */
.blog-card-title {
  font-size: 1em;
  color: #333;
  padding: 10px;
}

/* index.css */

@media (max-width: 768px) {
  .top-image {
    padding-top: 60px; /* Increase padding on mobile */
  }
}

.share-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.share-buttons button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.share-buttons button:hover {
  opacity: 0.8;
}


}
